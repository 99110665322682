import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugin/i18n";
import Analytic from "./plugin/analytic";
import "bootstrap";
import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";

const formatter = new Intl.NumberFormat();
const translate = new i18n({ store });
const analytic = new Analytic();
const app = createApp(App);
app.config.globalProperties.$filters = {
  money(value) {
    if (typeof value !== "number") {
      return value;
    }
    return formatter.format(value);
  },
  humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  },
  getItemImage(image) {
    if (!image) {
      return image;
    }
    if (image?.url) {
      if (image.url.includes(".mp4")) {
        return "url(images/mp4.png)";
      }
      if (image.url.includes(".webm")) {
        return "url(images/webm.png)";
      }
      if (image.url.includes(".ogg")) {
        return "url(images/ogg.png)";
      }
    }
    if (image.includes("https://") || image.includes("http://")) {
      return 'url("' + image + '")';
    }
    return image
      ? 'url("' +
          `${window.location.protocol}//${window.location.host}${image}` +
          '")'
      : "";
  },
};
app
  .use(translate)
  .use(analytic)
  .use(store)
  .use(router)
  .use(VCalendar, {})
  .use(VueTelInput)
  .mount("#app");
