<template>
   <div>Тут будет страница "Спасибо за заявку услуги"</div>
</template>

<script>
export default {
}
</script>

<style></style>
