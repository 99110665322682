<template>
  <div class="breadcrumb-holder desktop-breadcrumb" v-if="breadcrumbs.length">
    <ul class="breadcrumb" >
      <li>
        <router-link :to="{name:'home'}" :exact-active-class="'active'">{{ $static('menu.home', 'Головна') }}</router-link>
      </li>
      <li v-for="(category, index) in breadcrumbs" :key="index">
        <router-link v-if="index < breadcrumbs.length-1" :to="'/catalog/'+categoryURL(category)+`?hash=${category.hash}`" :activeClass="'active'"
                     :class="{'with-level': category.list.length}">
          {{ $dynamic(category, category.name, 'name') }}
        </router-link>
        <span v-else>{{ $dynamic(category, category.name, 'name') }}</span>
      </li>
    </ul>
  </div>

  <div class="category-panel" id="category_panel">
    <div class="category-panel-holder">
      <div class="panel-name">{{ $static('categories.title', 'категорії') }}:</div>
      <ul class="categories-list" @mousedown="e => mouseDownHandlerTarget(e,'categories-list')">
        <li>
          <router-link :to="{name:'home'}" :exact-active-class="'active'">
            <span class="text">{{ $static('menu.home', 'Головна') }}</span>
          </router-link>
        </li>

        <li v-for="(category,index) in top" :key="'category-'+index">
          <router-link :to="'/catalog/'+categoryURL(category)+`?hash=${category.hash}`"
                       :class="{'active': root && root.hash === category.hash}">
            <span class="text">{{ $dynamic(category, category.name, 'name') }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>

  <div id="subcategory_panel" class="subcategory-panel" :class="{'has-level': selected.level > 0}" v-if="selected">
    <div class="subcategory-holder">
      <div class="subcategory-back" v-if="selected.level > 0">
        <a href="javascript:void(0);" @click="selectParent">
          <img src="/images/icons/back.svg" alt="">
          <span class="text">Назад</span>
        </a>
      </div>
      <ul class="subcategory-list" @mousedown="e => mouseDownHandlerTarget(e,'subcategory-list')">
        <li v-for="(category,index) in selected_list" :key="'subcategory-'+index">
          <router-link :to="'/catalog/'+categoryURL(category)+`?hash=${category.hash}`"
                       :class="{'with-level': category.list.length, 'active': selected && category.hash === routerHash}">
            <span class="text">{{ $dynamic(category, category.name, 'name') }}</span>
            <img src="/images/icons/right.svg" alt="" v-if="category.list.length">
          </router-link>
        </li>
      </ul>
    </div>
  </div>

  <div class="breadcrumb-holder mobile-breadcrumb" v-if="breadcrumbs.length">
    <ul class="breadcrumb" >
      <li>
        <router-link :to="{name:'home'}" :exact-active-class="'active'">{{ $static('menu.home', 'Головна') }}</router-link>
      </li>
      <li v-for="(category, index) in breadcrumbs" :key="index">
        <router-link v-if="index < breadcrumbs.length-1" :to="'/catalog/'+categoryURL(category)+`?hash=${category.hash}`" :activeClass="'active'"
                     :class="{'with-level': category.list.length}">
          {{ $dynamic(category, category.name, 'name') }}
        </router-link>
        <span v-else>{{ $dynamic(category, category.name, 'name') }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name    : 'AdvancedMenu',
  props   : ['categories', 'menu_type', 'menu'],
  data(){
    return{
      top:[],
      list: []
    }
  },
  computed: {
    // top() {
    //   return this.menu?.settings ? this.menu.settings.map(i => {
    //     i.root        = `x${i.level}x${i.id}`;
    //     i.breadcrumbs = [i.root];
    //     return i;
    //   }).map(this.getCategory).filter(x => x.info?.url) : []
    // },
    // list() {
    //   return this.top.length ? this.top.reduce(this.unwrap, []) : []
    // },
    router() {
      return this.$route
    },
    routerHash() {
      return this.router.query.hash
    },
    selected() {
      return this.routerHash ? this.list.find(x => x.hash === this.routerHash) : undefined
    },
    root() {
      return this.selected?.root ? this.list.find(x => x.hash === this.selected.root) : undefined
    },
    breadcrumbs(){
      return this.selected?.breadcrumbs ? this.selected.breadcrumbs.map(x => this.list.find(i => i.hash === x)) : []
    },
    parent() {
      return this.selected?.parrent ? this.list.find(x => x.hash === this.selected.parrent) : undefined
    },
    selected_list() {
      return this.selected?.list?.length ? this.selected?.list.filter(x => x.info?.url) : this.parent ? this.parent.list.filter(x => x.info?.url) : []
    }
  },
  methods : {
    categoryURL(category){
      return category?.info?.url ? category.info.url : ''
    },
    unwrap(prev, current) {
      if (current.list?.length) {
        current.list.reduce(this.unwrap, prev)
      }
      prev.push(Object.assign({}, current))
      return prev
    },
    getCategory(category) {
      category.hash = `x${category.level}x${category.id}`
      if (category.list?.length) {
        category.list = category.list.map(i => {
          i.root = category.root;
          i.breadcrumbs = [...category.breadcrumbs, `x${i.level}x${i.id}`];
          return i;
        }).map(this.getCategory).map(i => {
          i.parrent     = category.hash;
          return i;
        })
      }
      // console.log(category.id, this.categories, this.categories.find(x => x.id === category.id))
      let found = this.categories.find(x => x.id === category.id);
      if (!found){
        found = this.categories.find(x => x.name === category.name)
      }
      return Object.assign(category, found)
    },
    selectParent() {
      if (this.router.name === "Item") {
        this.$router.go(-1);
      }else{
        this.$router.push('/catalog/' + this.parent.info.url + `?hash=${this.parent.hash}`)
      }
    },
    mouseDownHandlerTarget(e, targetClass) {
      let target = e.target;
      while (!target.className.includes(targetClass)) {
        target = target.parentElement
      }
      window.ele = target;
      window.mouseDownHandler(e)
    },
    calcMenu(value){
      this.top = value.settings.map(i => {
        i.root        = `x${i.level}x${i.id}`;
        i.breadcrumbs = [i.root];
        return i;
      }).map(x => this.getCategory(x))
      this.list = this.top.reduce(this.unwrap, []);
    }
  },
  mounted() {
    // (function ($) {
    //   'use strict';
    //   $('#category_panel').stick_in_parent({offset_top: 0});
    //   $('#subcategory_panel').stick_in_parent({offset_top: 60});
    // })(window.$);
    this.calcMenu(this.menu)

    // console.log(this.top, this.list);

    if (this.selected){
      this.$store.commit('setSearchBy', this.selected.search_by)

    }
  },
  watch: {
    menu(val){
      this.calcMenu(val)
    },
    selected(val) {
      if(val) {
        this.$store.commit('setSearchBy', val.search_by)
      }
      // if (val) {
        // setTimeout(function (){
        //   'use strict';
        //   window.$('#subcategory_panel').stick_in_parent({offset_top: 60})
        // }, 1000)
      // }
    }
  }
}
</script>
