<template>
  <header :class="{ 'if-logined': this.userInfo }">
    <div class="header-holder">
      <div class="menu-button">
        <a href="javascript:void(0);" class="show-menu-btn" @click.prevent="handleShowMenuBtnClick">
          <span></span>
          <span></span>
          <span></span>
        </a>
        <SearchItem />
      </div>
      <div class="header-info" v-if="s_info" v-html="s_info"></div>
      <div class="logo-holder">
        <router-link :to="{ name: 'home' }">
          <img :src="logo">
        </router-link>
      </div>

      <!--      <div class="header-info right-header-info" v-if="phones">-->
      <!--        <template v-for="(value,key) in phones " :key="key">-->
      <!--          <a :href="'tel:'+value.real" v-html="value.text" v-if="value.isNumber"></a>-->
      <!--          <p v-else v-html="value.text">-->
      <!--          </p>-->
      <!--        </template>-->
      <!--      </div>-->
      <div class="header-btns" v-if="userInfo">
        <a v-if="settings.consultant" :href="settings.consultant" class="default-btn btn-outline-light chat-btn">
          <img src="/images/icons/telegram.svg" alt="Зв’язок з менеджером">
          <span class="text">Зв’язок з менеджером</span>

        </a>
        <!--        <div v-if="settings.consultant" class="line"></div>-->
        <router-link :to="{ name: 'reviews' }" class="default-btn btn-outline-light review-btn">
          <img src="/images/icons/reviews.svg" alt="Відгуки">
          <span class="text">Відгуки</span>
        </router-link>
        <!--        <div class="line desktop-hidden"></div>-->
        <a href="javascript:void(0)" @click="sendWebLink"
          class="default-btn btn-outline-light review-btn desktop-hidden">
          <img src="/images/icons/web.svg" alt="Web Версія">
          <span class="text">Web Версія</span>
        </a>
      </div>
      <div class="login-btn" v-else-if="BOT_NAME && !isWebApp">
        <div class="login-btn-holder">
          <telegram-login-temp v-if="BOT_NAME" mode="callback" :size="getSize" requestAccess="write"
            :telegram-login="BOT_NAME" @loaded='telegramLoadedCallbackFunc' @callback="yourCallbackFunction" />
        </div>
      </div>
      <div class="header-btns" :class="{ 'if-logined': this.userInfo }">

        <!--        <div class="line"></div>-->
        <router-link :to="{ name: 'reviews' }" class="default-btn btn-outline-light review-btn">
          <img src="/images/icons/reviews.svg" alt="Відгуки">
          <span class="text">Відгуки</span>
        </router-link>
      </div>
      <!--      <div class="lang-panel">-->
      <!--        <div class="dropdown show" v-if="lang">-->
      <!--          <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown">-->
      <!--            <img :src="current.icon" alt="">-->
      <!--            {{ current.short }}-->
      <!--          </a>-->
      <!--          <div class="dropdown-menu" x-placement="bottom-end">-->
      <!--            <a v-for="(l,index) in lang" :key="index" class="dropdown-item active "-->
      <!--               href="javascript:void(0)" @click="setLang(l)"-->
      <!--               :class="{'active': l.text === current.text}">-->
      <!--              <img :src="l.icon" alt="">-->
      <!--              {{ l.short }}-->
      <!--            </a>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </header>
</template>

<script>
{/* <div class="personal_area-sidebar through-personal-sidebar"> --> <!--if not logged in add class .not-logged-->
    <div class="sidebar-name h2 desktop-hidden">{{ $static('cabinet.personal-title', 'Особистий кабінет') }}</div>
    <a href="javascript:void(0);" class="close-personal-sidebar desktop-hidden">
      <img src="/images/icons/close.svg" alt="">
    </a>
    <div class="personal-area-sidebar-holder" v-if="userInfo">
      <div class="logged-in">
        <div class="logged-in-body">
          <span class="user-avatar image bg-cover" v-if="userInfo.photo_url"
            :style="{ 'background-image': 'url(' + userInfo.photo_url + ')' }"></span>
          <span class="user-avatar" v-else-if="userInfo.first_name">{{ userInfo.first_name.substring(0, 1) }}</span>
          <span class="user-avatar image bg-cover" v-else-if="userInfo.username">{{ userInfo.username.substring(0, 1)
            }}</span>
          <div class="avatar-info">
            <div class="name">{{ userInfo.first_name }} {{ userInfo.last_name }}</div>
            <div class="bonus-panel" v-if="account">
              <span>Ваші бонуси: <span class="num">{{ bonus }}</span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="personal_area-menu">
        <ul>
          <li>
            <router-link :to="{ name: 'CabinetHistory' }" :active-class="'active'">
              <span class="icons-holder">
                <img src="/images/icons/history.svg" alt="">
              </span>
              <span class="text">{{ $static('cabinet.title2', 'Історія замовлень') }}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'CabinetInfo' }" :exact-active-class="'active'">
              <span class="icons-holder">
                <img src="/images/icons/user.svg" alt="">
              </span>
              <span class="text">{{ $static('cabinet.title3', 'Особисті дані') }}</span>
            </router-link>
          </li>

          <!--          <li>-->
          <!--            <router-link :to="{name: 'CabinetDelivery'}" :active-class="'active'">-->
          <!--									<span class="icons-holder">-->
          <!--                    <img src="/images/icons/loc.svg" alt="">-->
          <!--                  </span>-->
          <!--              <span class="text">Налаштування доставки</span>-->
          <!--            </router-link>-->
          <!--          </li>-->
        </ul>
      </div>
      <div class="logout" v-if="!isWebApp">
        <a href="javascript:void(0)" @click="logout">
          <span class="icons-holder">
            <img src="/images/icons/logout.svg" alt="">
          </span>
          <span class="text">{{ $static('cabinet.logout', 'Вийти з аккаунту') }}</span>
        </a>
      </div>
      <!--      <div class="button-holder">-->
      <!--        <button class="default-btn btn-no-icon">Увійти в кабінет</button>-->
      <!--      </div>-->
    </div>
    <div v-else>
      <p style="margin: 10px 15px;">{{
        $static(
        'cabinet.must-login',
        'Необхідна авторизація. Натисніть "Увійти як" - через телеграмм.'
        )
        }}</p>
    </div>
  </div> */}
import SearchItem from '@/components/SearchItem';
import { telegramLoginTemp } from 'vue3-telegram-login';
import { fixBodyPadding } from '@/utils/fixBodyPadding';

export default {
  name: 'MenuHeader',
  components: { SearchItem, 'telegram-login-temp': telegramLoginTemp },
  emits: ['auth'],
  data() {
    return {
      init: false
    }
  },
  computed: {
    userInfo() {
      return this.account?.telegram ? this.account?.telegram : this.account
    },
    account() {
      return this.$store.getters.getAccount
    },
    bonus() {
      return this.account?.bonus
    },
    isWebApp() {
      return this.$store.getters.isWebApp
    },

    categories() {
      return this.$store.getters.getCategories.filter(i => !i.disabled).sort((a, b) => a.order - b.order)
    },
    getSize() {
      return window.innerWidth < 500 ? 'small' : 'medium'
    },
    settings() {
      return this.$store.getters.getSettings
    },
    BOT_NAME() {
      return this.settings.BOT_NAME ? this.settings.BOT_NAME : false
    },
    logo() {
      return this.settings && this.settings.logo ? this.settings.logo : ''
    },
    s_info() {
      return this.settings && this.settings.s_info ? this.settings.s_info : false
    },
    phones() {
      return this.settings && this.settings.phone ?
        this.settings.phone.split(',').map(x => x.trim())
          .map(x => { return { real: x.replace(/^\D+/g, ''), text: x, isNumber: x.startsWith('+') } }) : false
    },
  },
  watch: {
    settings(val) {
      if (val && val.favicon) {
        let link = document.querySelector('link[rel~=\'icon\']');
        if (link) {
          link.href = val.favicon
        }
      }
    }
  },
  methods: {
    sendWebLink() {
      // let user_info = JSON.parse(localStorage.getItem('user_info'));
      // if (user_info === null) {
      //   user_info = this.userInfo;
      // }
      fetch('/public/get_my_link', {
        method: 'POST',
        body: JSON.stringify({ user_info: window.Telegram.WebApp.initData, ref: window.location.href })
      }).then(() => {
        window.Telegram.WebApp.close();
      })
    },
    telegramLoadedCallbackFunc() {
      // console.log('script is loaded')
      this.isLoaded = true
    },
    yourCallbackFunction(user) {
      // console.log(user);
      localStorage.setItem('user_info', JSON.stringify(user));
      this.$store.commit('setUserInfo', user);
      this.$emit('auth', user);
    },
    handleShowMenuBtnClick() {
      console.log("first")
      this.$store.commit('setBuskedOpen', false);
      this.$store.commit('setReadMoreOpen', false)

      fixBodyPadding();
      // Обнуляем классы видимости, для того чтоб не было оставшихся видимых элементов при переходе между пунктами мобильного меню

      window.$('.personal-area-modal-wrapper-bg').removeClass('is-visible');
      window.$('.personal_area-sidebar').removeClass('opened');
      window.$('.login-trigger-item').removeClass('active');

      window.$('.cart-modal-wrapper-bg').removeClass('is-visible');
      window.$('.quick-card-sticker-li').removeClass('active');
      window.$('#quick_cart').removeClass('opened');

      window.$('.overlay-menu').toggleClass('active');
      window.$('.open-nav').toggleClass('is-visible');
      window.$('.show-menu-btn').toggleClass('open');
      console.log("second")
    }
  }
}
</script>