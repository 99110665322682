<template>
  <main :class="{ 'bg-contain': isContain }">
    <div class="overlay-menu">
      <div class="overlay-top">
        <router-link :to="{ name: 'home' }">
          <div class="logo">
            <img :src="logo">
          </div>
        </router-link>
        <a class="close-menu-btn" href="javascript:void(0);" @click.prevent="handleCloseMenuBtnClick">
          <img src="/images/icons/close.svg">
        </a>
      </div>

      <div class="overlay-menu-wrapper">
        <div class="menu-list"> <!--if menu has sublevels then add class .with-sublevel-->
          <div class="back-level">
            <a class="back_level_trigger" href="javascript:void(0);">
              <img src="/images/icons/left.svg">
              <span class="text">Назад</span>
            </a>
          </div>
          <ul>
            <li class="dflt-link">
              <router-link :to="{ name: 'home' }" :exact-active-class="'active'">
                <span class="text">{{ $static('menu.home', 'Головна') }}</span>
              </router-link>
            </li>

            <!--            <li class="categories-menu" v-if="menu_type==='advanced' && menu">-->
            <!--              <span class="title-categories-menu">{{ $static('menu.ct', 'Категорії') }}</span>-->
            <!--              <ul class="categories-list">-->
            <!--                <li v-for="(category,index) in top" :key="'category-'+index">-->
            <!--                  <router-link :to="'/catalog/'+categoryURL(category)+`?hash=${category.hash}`">-->
            <!--                    <span class="text">{{ $dynamic(category, category.name, 'name') }}</span>-->
            <!--                  </router-link>-->
            <!--                </li>-->
            <!--              </ul>-->
            <!--            </li>-->

            <!--            <li class="categories-menu" >-->
            <!--              <span class="title-categories-menu">{{ $static('menu.ct', 'Категорії') }}</span>-->
            <!--              <ul class="categories-list">-->
            <!--                <li v-for="(category, index) in categories" :key="index">-->
            <!--                  <router-link :to="'/catalog/'+category.info.url" :activeClass="'active'">-->
            <!--                    <span class="text">{{ $dynamic(category, category.name, 'name') }}</span>-->
            <!--                  </router-link>-->
            <!--                </li>-->
            <!--              </ul>-->
            <!--            </li>-->

            <li v-if="phones && phones.length" class="phones dflt-link">
              <span class="title-categories-menu">{{ $static('menu.phones', 'Телефони') }}</span>
              <div class="header-info right-header-info">
                <template v-for="(value, key) in phones " :key="key">
                  <a :href="'tel:' + value.real" v-html="value.text" v-if="value.isNumber"></a>
                  <p v-else v-html="value.text">
                  </p>
                </template>
              </div>
            </li>

            <li class="dflt-link">
              <router-link :to="{ name: 'about' }">
                <span class="text">{{ $static('menu.about', 'Про нас') }}</span>
              </router-link>
            </li>
            <li class="dflt-link" v-if="settings?.catalog_mod == false">
              <router-link :to="{ name: 'delivery' }">
                <span class="text">{{ $static('menu.delivery', 'Доставка та оплата') }}</span>
              </router-link>
            </li>
            <li class="dflt-link" v-if="settings?.catalog_mod == false">
              <router-link :to="{ name: 'policy' }">
                <span class="text">{{ $static('menu.policy', 'Політика конфіденційності') }}</span>
              </router-link>
            </li>
            <li class="dflt-link" v-if="settings?.catalog_mod == false">
              <router-link :to="{ name: 'dostup' }">
                <span class="text">{{ $static('menu.dostup', 'Політика доступності') }}</span>
              </router-link>
            </li>
            <li class="dflt-link" v-if="settings?.catalog_mod == false">
              <router-link :to="{ name: 'offerta' }">
                <span class="text">{{ $static('menu.offerta', 'Публічна оферта') }}</span>
              </router-link>
            </li>

          </ul>
        </div>
      </div>
      <div class="bottom-overlay-nav">
        <div class="chat-btn-holder mobile-hidden">
          <router-link class="chat-btn" :to="{ name: 'CabinetHistory' }">
            <img src="/images/icons/user-filled.svg" alt="">
            <span class="text">{{ $static('menu.cabinet', 'Кабінет') }}</span>
          </router-link>
        </div>

        <div class="chat-btn-holder" v-if="settings.consultant">
          <a :href="settings.consultant" class="chat-btn">
            <img src="/images/icons/telegram.svg" alt="">
            <span class="text">{{ $static('menu.mm', 'Зв’язок з менеджером') }}</span>
          </a>
        </div>


        <div class="menu-info-holder">
          <div class="menu-info time-info" v-if="s_info" v-html="s_info"></div>
        </div>
        <div class="overlay-lang-panel" v-if="lang?.length">
          <div class="lang-holder d-flex align-items-center">
            <div class="custom-label">{{ $static('menu.lng', 'Мова') }}:</div>
            <div class="lang-links">
              <a v-for="(l, index) in lang" :key="index" href="javascript:void(0)" @click="setLang(l)"
                :class="{ 'active': l.short === current.short }">
                <!--                <img :src="l.icon" alt="">-->
                {{ l.short }}
              </a>
            </div>
          </div>
        </div>
        <!--        <div class="soc-link">-->
        <!--          <a v-if="settings.instagram" :href="settings.instagram"># instagram</a>-->
        <!--        </div>-->
        <div class="develop-by" v-if="settings && settings.watter_mark">
          <a href="#">
            <div class="name"><img src="/images/developedby-dark.svg" alt="uxlove studio" class="developedby"></div>
            <img src="/images/uxlove-logo-dark.svg" alt="uxlove studio">
          </a>
        </div>
      </div>

    </div>
    <div class="open-nav" @click="handleCloseMenuBtnClick"></div>
    <div class="fixed-holder-btn" v-if="demoLink">
      <a href="javascript:void(0)" @click="openDemo" class="fixed-btn infinite animated pulse">
        <span class="btn-text">{{ demo_text }}</span>
      </a>
    </div>


    <MobileNavAndBasket />

    <MenuHeader @auth="fetchAccount" />

    <Categories></Categories>

    <router-view />
    <div class="personal-sidebar-bg"></div>
  </main>
  <footer>
    <div class="footer-holder">
      <div class="footer-contacts">
        <div class="holder">
          <h5>{{ $static('footer.tot-contacts', 'Контакти') }}</h5>
          <div class="contact-box">
            <div class="contact-item" v-if="settings.email">
              <div class="name">{{ $static('footer.email', 'Электронна пошта') }}:</div>
              <a :href="'mailto:' + settings.email">{{ settings.email }}</a>
            </div>
            <div class="contact-item" v-if="settings.phone">
              <div class="name">{{ $static('footer.phone', 'Телефон') }}:</div>
              <!--              <a :href="'tel:'+settings.phone">{{settings.phone}}</a>-->
              <template v-for="(         value, key         ) in phones         " :key="key">
                <a :href="'tel:' + value.real" v-html="value.text" v-if="value.isNumber">

                </a>
                <p v-else v-html="value.text">
                </p>
              </template>

            </div>

            <div class="contact-item" v-if="settings.socialNetworks && settings.socialNetworks.length">
              <div class="name">{{ $static('footer.social', 'Соціальні мережі') }}:</div>
              <a v-for="(socialNetwork, socialNetworkIndex) in settings.socialNetworks" :href="socialNetwork.link"
                :key="socialNetworkIndex" target="_blank" rel="noopener noreferrer">
                {{ socialNetwork.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-links footer-contact-links">
        <div class="holder">
          <ul>
            <li>
              <router-link :to="{ name: 'about' }">
                <span class="text">{{ $static('menu.about', 'Про нас') }}</span>
              </router-link>
            </li>
            <li v-if="settings?.catalog_mod == false">
              <router-link :to="{ name: 'delivery' }">
                <span class="text">{{ $static('menu.delivery', 'Доставка та оплата') }}</span>
              </router-link>
            </li>
            <li v-if="settings?.catalog_mod == false">
              <router-link :to="{ name: 'policy' }">
                <span class="text">{{ $static('menu.policy', 'Політика конфіденційності') }}</span>
              </router-link>
            </li>
            <li v-if="settings?.catalog_mod == false">
              <router-link :to="{ name: 'dostup' }">
                <span class="text">{{ $static('menu.dostup', 'Політика доступності') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'offerta' }">
                <span class="text">{{ $static('menu.offerta', 'Публічна оферта') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'CabinetHistory' }">
                <span class="text">{{ $static('menu.cabinet', 'Кабінет') }}</span>
              </router-link>
            </li>
          </ul>
          <div class="logos" v-if="settings?.catalog_mod == false">
            <img src="/images/icons/mastercard.svg" alt="mastercard">
            <img src="/images/icons/visa.svg" alt="visa">
          </div>
        </div>
      </div>
      <div class="copyright">
        <div class="copy">
          &copy; {{ $static('footer.copy', `2023 Копіювання матеріалів сайту без дозволу заборонено`) }}
        </div>

        <div class="develop-by" v-if="settings && settings.watter_mark">

          <a href="https://ux.im/">
            <div class="name"><img src="/images/developedby.svg" alt="uxlove studio" class="developedby"></div>
            <img src="/images/uxlove-logo.svg" alt="uxlove studio">
          </a>
        </div>
      </div>
    </div>
  </footer>

  <!--  <div class="modal custom-modal fade" id="consult_modal" tabindex="-1" role="dialog" aria-hidden="true">-->
  <!--    <div class="modal-dialog modal-dialog-centered" role="document">-->
  <!--      <div class="modal-content">-->
  <!--        <div class="modal-header">-->
  <!--          <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
  <!--            <img src="/images/icons/close.svg" alt="">-->
  <!--          </button>-->
  <!--        </div>-->
  <!--        <div class="modal-body">-->
  <!--          <h2>{{ $static('modal-callback.title', 'Отримати консультацію') }}</h2>-->
  <!--          <p>{{ $static('modal-callback.text', `Введіть свій номер телефону нижче,-->
  <!--            і наш менеджер зв’яжеться з Вами-->
  <!--            за декілька хвилин`) }}</p>-->
  <!--          <form>-->
  <!--            <div class="form-group" :class="{ 'error': errors['phone'] }">-->
  <!--              <input type="text" class="form-control custom-input" @focus="delError('phone')" placeholder="+380998787858"-->
  <!--                v-model="callbackPhone">-->
  <!--              <p class="help-text" v-for="(err, key) in errors['phone']" :key="key">{{ err }}</p>-->
  <!--            </div>-->
  <!--            <div class="button-holder">-->
  <!--              <a href="javascript:void(0)" class="default-btn" @click="sendCallback">-->
  <!--                <span class="btn-text">{{ $static('modal-callback.button', 'Отримати консультацію') }}</span>-->
  <!--                <img src="/images/icons/send-light.svg" alt="">-->
  <!--              </a>-->
  <!--            </div>-->
  <!--          </form>-->
  <!--          <div class="attention-apply">{{ $static('modal-callback.info1', 'При кліці на кнопку я погоджуюся з') }}-->
  <!--            <router-link :to="{ name: 'policy' }">{{ $static('modal-callback.info2', `політикою обробки персональних-->
  <!--              даних`) }}-->
  <!--            </router-link>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->


  <OnStartModal />
  <OnCloseModal />
  <!-- <div v-if="$route.path === '/'" class="frame-modal-wrapper-bg"></div> -->
</template>

<script>

import OnCloseModal from '@/components/OnCloseModal';
import OnStartModal from '@/components/OnStartModal';
import MobileNavAndBasket from '@/components/MobileNavAndBasket';
import Categories from '@/components/Categories';
import MenuHeader from '@/components/MenuHeader';

import { fixBodyPadding } from '@/utils/fixBodyPadding'

export default {
  name: 'Shop',
  components: { OnCloseModal, OnStartModal, MenuHeader, Categories, MobileNavAndBasket },
  data() {
    return {
      callbackPhone: '',
      errors: {},
      demoLink: undefined,
      demo_text: undefined,
      current: {
        icon: '/storage/i18n/ua.svg',
        title: 'Україньска',
        short: 'UA',
        value: '/storage/i18n/original.json'
      },
    }
  },
  methods: {
    categoryURL(category) {
      return category?.info?.url ? category.info.url : ''
    },
    getCategory(category) {
      category.hash = `x${category.level}x${category.id}`
      if (category.list?.length) {
        category.list = category.list.map(i => {
          i.root = category.root;
          i.breadcrumbs = [...category.breadcrumbs, `x${i.level}x${i.id}`];
          return i;
        }).map(this.getCategory).map(i => {
          i.parrent = category.hash;
          return i;
        })
      }
      return Object.assign(category, this.categories.find(x => x.id === category.id))
    },
    openDemo() {
      if (window.Telegram?.WebApp?.initData) {
        window.Telegram.WebApp.openTelegramLink(this.demoLink)
        window.Telegram.WebApp.close()
        return;
      }
      window.location.href = this.demoLink;
    },
    setLang(lang) {
      this.current = lang;
      this.fetchLang();
    },
    fetchLang() {
      fetch(this.current.value).then(response => response.json()).then(data => {
        if (data) {
          localStorage.setItem('$lang', this.current.short);
          this.$load(data, this.current.short)
        }
      })
    },
    fetchOrigin() {
      return fetch('/storage/i18n/original.json').then(response => response.json()).then(data => {
        if (data) {
          this.$load(data, this.current.short)
        }
      })
    },
    delError(key) {
      delete this.errors[key]
    },
    validate() {
      this.errors = {};
      if (this.callbackPhone.length < 8) {
        this.errors.phone = [this.$static('order.phone-error', 'Введіть коректний номер')];
        return false
      }
      return true;
    },
    fetchAccount() {
      let ok = false
      let userInfo = localStorage.getItem('user_info');
      let headers = {};
      let initCache = localStorage.getItem('initData');
      if (initCache && initCache.length) {
        headers.initData = initCache;
        ok = true;
      }
      let body = {}
      if (userInfo) {
        body.user = JSON.parse(userInfo)
        this.$store.commit('setUserInfo', body.user)
        ok = true
      }
      if (!ok) {
        return new Promise(resolve => resolve())
      }
      const searchParams = new URLSearchParams(window.location.search);
      let fbc = undefined;
      let fbp = undefined;
      if (searchParams.has('fbclid')) {
        fbc = searchParams.get('fbclid');
        localStorage.setItem('_fbc', fbc);
      } else {
        fbc = localStorage.getItem('_fbc');
      }
      fbp = localStorage.getItem('_fbp');
      if (fbc) {
        headers.FBC = fbc;
      }
      if (fbp) {
        headers.FBP = fbp.replaceAll("_", ".");
      }

      return fetch(
        '/public/account',
        {
          method: 'POST',
          body: JSON.stringify(body),
          headers: headers
        }
      ).then(result => result.json()).then(json => {
        if (json && json.result) {
          this.$store.commit('setAccount', json.result)
        }
        if (json?.error) {
          window.localStorage.removeItem('user_info')
        }
      })
    },
    getPlugins() {
      return fetch('/public/plugin').then(response => response.json()).then(data => {
        data.forEach(x => {
          let str = x.type
          let capitalizeType = [...str][0].toUpperCase() + [...str].slice(1).join('');
          this.$store.commit(`set${capitalizeType}`, x.list);
          // this[x.type] = x.list;
          if (x.list.length) {
            let id = x.list[0].id;
            switch (x.type) {
              case 'payment':
                this.$store.commit('setDefaultPayment', id)
                break;
              case 'delivery':
                this.$store.commit('setDefaultDelivery', id)
                break
            }
          }
        });
      });
    },
    getOptions() {
      return fetch('/public/options').then(response => response.json()).then(data => {
        data.result.forEach(o => {
          /*eslint no-case-declarations: "off"*/
          switch (o.option_id) {
            case 'tags':
              this.$store.commit('setTags', o.list);
              break;
            case 'category':
              this.$store.commit('setCategories', o.list.map(category => {
                if (category.info?.groups) {
                  category.info.groups = category.info.groups.sort((a, b) => { return a.order - b.order })
                }
                return category
              }));
              break;
            default:
              this.$store.commit('setOptions', o);
              break;
          }
        });

        if (data.settings) {
          this.$store.commit('setOptionSettings', data.settings);
        }
      });
    },
    async fetchSettings() {
      const resp = await fetch('/public/settings');
      const data = await resp.json();
      if (data) {
        this.$store.commit('setSettings', data);
      }
    },
    fetchPopup() {
      fetch('/public/templates', { method: 'POST', body: JSON.stringify({ name: ['modal_onstart', 'modal_onclose', 'home', 'menu'] }) })
        .then(response => response.json())
        .then(data => {
          if (data && data.length) {
            data.forEach(page => {
              if (page.name === 'home' && page.settings && !Array.isArray(page.settings.image)) {
                page.settings.image = [
                  {
                    url: page.settings.image,
                    link: '/catalog/' + page.settings.url,
                    title: page.settings.title,
                    subtitle: page.settings.subtitle
                  }
                ]
              }
              this.$store.commit('setPage', page)
            })
          }
        })
    },
    handleCloseMenuBtnClick() {
      this.$store.commit('setBuskedOpen', false);

      fixBodyPadding();

      window.$('.open-nav').removeClass('is-visible');
      window.$('.overlay-menu').removeClass('active');
      window.$('.show-menu-btn').removeClass('open');
    },

    updateScrollStyles() {
      let bottomOverlayNavTop = Number(document.querySelector(".bottom-overlay-nav").offsetTop);

      const menuElementArray = Array.from(document.querySelectorAll('.dflt-link'));
      if (menuElementArray.length === 0) return; // Проверка на наличие элементов

      const lastMenuElement = menuElementArray[menuElementArray.length - 1];

      let lastMenuElementbottom = Number(lastMenuElement.offsetTop) + Number(lastMenuElement.offsetHeight);

      const scrollElement = lastMenuElement.closest('ul');
      if (!scrollElement) return; // Проверка на наличие элемента

      const scrollElementTop = Number(scrollElement.offsetTop);

      if (lastMenuElementbottom > bottomOverlayNavTop) {
        scrollElement.style.overflowY = 'scroll';
        scrollElement.style.maxHeight = (bottomOverlayNavTop - scrollElementTop) + "px";
      } else {
        scrollElement.style.overflowY = '';
        scrollElement.style.maxHeight = '';
      }
    }
  },

  mounted() {
    // Слушатель события, определяющий будет ли скролл у списка бокового меню, если на него наезжает нижний элемент навигации, содержащий Кабинет и.т.д.. Актуален и для декстопа и мобильной версии
    document.addEventListener('DOMContentLoaded', this.updateScrollStyles);    // Вызов функции при загрузке страницы
    window.addEventListener('resize', this.updateScrollStyles);
    // ________________________________________________________
    // document.querySelector(".open-nav").addEventListener('click', () => {
    //   this.handleCloseMenuBtnClick()
    // })

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    });
    if (params.code) {
      this.$Code(params.code)
    }

    window.pos = { top: 0, left: 0, x: 0, y: 0 };
    window.ele = undefined;
    window.change = false;
    if (location.hostname === 'demo.ux.im') {
      this.demoLink = 'https://t.me/uxwebbot?start=demo'
      this.demo_text = this.$static('demo_btn_create.create', 'Створити безкоштовно')
    }
    if (location.hostname === 'ecommerce.ux.im') {
      this.demoLink = 'https://t.me/UXLoveBot?start=cloth_quiz'
      this.demo_text = this.$static('demo_btn_create.create2', 'Замовити в 1 клік')
    }

    function isTouchDevice() {
      return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
    }

    window.istouch = isTouchDevice();
    window.mouseDownHandler = function (e) {
      window.pos = {
        // The current scroll
        left: window.ele.scrollLeft,
        top: window.ele.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY
      };

      // Change the cursor and prevent user from selecting the text
      // ele.style.cursor = 'grabbing';

      if (!window.istouch) {
        window.ele.style.userSelect = 'none';
        window.change = false;
        document.addEventListener('mousemove', window.mouseMoveHandler);
        document.addEventListener('mouseup', window.mouseUpHandler);
      }
    };

    window.mouseMoveHandler = function (e) {
      // console.log('mouseMoveHandler', e)
      // How far the mouse has been moved
      window.change = true;
      const dx = e.clientX - window.pos.x;
      const dy = e.clientY - window.pos.y;
      // Scroll the element
      window.ele.scrollTop = window.pos.top - dy;
      window.ele.scrollLeft = window.pos.left - dx;
    };

    window.clearChange = function (e) {
      window.change = false;
    }
    window.mouseUpHandler = function () {
      // console.log('mouseUpHandler')
      document.removeEventListener('mousemove', window.mouseMoveHandler);
      document.removeEventListener('mouseup', window.mouseUpHandler);
      setTimeout(window.clearChange, 300)
    };

    document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

    if (window.Telegram) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.enableClosingConfirmation();
      // document.getElementById('subtitle').innerText = navigator.userAgent;
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        window.document.addEventListener('touchmove', function (e) {
          if (e.scale !== 1) {
            e.preventDefault();
          }
        }, { passive: false });
        window.Telegram.WebApp.onEvent('viewportChanged', window.Telegram.WebApp.expand)
      }
      if (window.Telegram.WebApp.initData && window.Telegram.WebApp.initData.length) {
        localStorage.setItem('initData', window.Telegram.WebApp.initData);
        // this.initData = window.Telegram.WebApp.initData
      }
    }

    // (function ($) {
    //   'use strict';

    //   $('#quick_card_panel_sticker a').on('click', function () {
    //     $('body').toggleClass('no-scroll');
    //     $('.overlay-menu').removeClass('active');
    //     $('.show-menu-btn').removeClass('open');
    //     $('.open-nav').removeClass('is-visible');
    //     $('#quick_cart').toggleClass('opened');
    //     $('.quick-card-sticker-li').toggleClass('active');
    //     $('.frame-modal-wrapper-bg').toggleClass('is-visible');
    //     return false;
    //   });
    //   $('.login-trigger').on('click', function () {
    //     $('.personal_area-sidebar').toggleClass('opened');
    //     $('body').toggleClass('no-scroll');
    //     $('.show-menu-btn').removeClass('open');
    //     $('#quick_cart').removeClass('opened');
    //     $('.overlay-menu').removeClass('active');
    //     $('.quick-card-sticker-li').removeClass('active');

    //     $('.quick-card-sticker-li').toggleClass('active');

    //     $('.personal-sidebar-bg').toggleClass('is-visible');
    //     $('.open-nav').removeClass('is-visible');
    //     return false;
    //   });
    //   $('.close-personal-sidebar').on('click', function () {
    //     $('.personal_area-sidebar').removeClass('opened');
    //     $('body').removeClass('no-scroll');
    //     $('.personal-sidebar-bg').removeClass('is-visible');
    //     return false;
    //   });
    //   $('.personal-sidebar-bg').on('click', function () {
    //     $('.personal_area-sidebar').removeClass('opened');
    //     $('body').removeClass('no-scroll');
    //     $(this).removeClass('is-visible');
    //     return false;
    //   });

    //   $('.show-menu-btn').on('click', function () {
    //     fixBodyPadding();
    //     $('.overlay-menu').toggleClass('active');
    //     $(this).toggleClass('open');
    //     $('body').toggleClass('no-scroll');
    //     $('#quick_cart').removeClass('opened');
    //     $('.quick-card-sticker-li').removeClass('active');
    //     $('.frame-modal-wrapper-bg').removeClass('is-visible');
    //     $('.open-nav').toggleClass('is-visible');
    //     return false;
    //   });
    //   $('.overlay-menu .close-menu-btn').on('click', function () {
    //     fixBodyPadding();
    //     $('.overlay-menu').removeClass('active');
    //     $('.show-menu-btn').removeClass('open');
    //     $('body').removeClass('no-scroll');
    //     $('.open-nav').removeClass('is-visible');
    //     return false;
    //   });
    //   $('.open-nav').on('click', function () {
    //     fixBodyPadding();
    //     $('.overlay-menu').removeClass('active');
    //     $('.show-menu-btn').removeClass('open');
    //     $('body').removeClass('no-scroll');
    //     $(this).removeClass('is-visible');
    //     return false;
    //   });
    //   $(function () {
    //     if ($(window).scrollTop() >= 100) {
    //       $('.fixed-holder-btn').addClass('stickybottom');
    //     }
    //     $(window).scroll(function () {
    //       if ($(this).scrollTop() >= 100) {
    //         $('.fixed-holder-btn').addClass('stickybottom');
    //       } else {
    //         $('.fixed-holder-btn').removeClass('stickybottom');
    //       }
    //     });
    //   });

    //   $(function () {
    //     if ($(window).scrollTop() >= 300) {
    //       $('.fixed-options-panel').addClass('stickybottom');
    //     }
    //     $(window).scroll(function () {
    //       if ($(this).scrollTop() >= 300) {
    //         $('.fixed-options-panel').addClass('stickybottom');
    //       } else {
    //         $('.fixed-options-panel').removeClass('stickybottom');
    //       }
    //     });
    //   });
    // })(window.$);

    let options = this.getOptions();
    let plugins = this.getPlugins();
    let account = this.fetchAccount();
    window.accountLoad = account;
    let settings = this.fetchSettings();
    let popUp = this.fetchPopup();

    Promise.all([options, account, settings, plugins, popUp]).then(() => {
      window.$('.preloader-page').removeClass('proccess-load')
    })
  },
  computed: {
    webAppStatus() {
      return this.$store.getters.isWebApp
    },
    isContain() {
      return this.settings?.contain
    },
    top() {
      return this.menu?.settings ? this.menu.settings.map(i => {
        i.root = `x${i.level}x${i.id}`;
        i.breadcrumbs = [i.root];
        return i;
      }).map(this.getCategory) : []
    },
    menu_type() {
      return this.$store.getters.getSettings ? this.$store.getters.getSettings.menu_type : undefined
    },
    menu() {
      return this.$store.getters.getPages ? this.$store.getters.getPages.menu : undefined
    },

    categories() {
      return this.$store.getters.getCategories.filter(i => !i.disabled).sort((a, b) => a.order - b.order)
    },
    settings() {
      return this.$store.getters.getSettings
    },
    lang() {
      return this.settings ? this.settings.lang : this.settings
    },
    logo() {
      return this.settings && this.settings.logo ? this.settings.logo : ''
    },
    initData() {
      return window.location.href
    },
    s_info() {
      return this.settings && this.settings.s_info ? this.settings.s_info : false
    },
    phones() {
      return this.settings && this.settings.phone ?
        this.settings.phone.split(',').map(x => x.trim())
          .map(x => { return { real: x.replace(/^\D+/g, ''), text: x, isNumber: x.startsWith('+') } }) : false
    },

    local() {
      return this.$local()
    },
    dynamics() {
      return this.$dynamics()
    },
    isReviewsView() {
      return this.$route.path === '/reviews' ? true : false;
    }
  },
  watch: {
    lang(val) {
      if (val) {
        this.fetchOrigin().then(() => {
          const lang = localStorage.getItem('$lang');
          if (lang) {
            let x = val.find(i => i.short === lang);
            if (x) {
              this.current = x;
              this.fetchLang()
            }
          }
        })
      }
    }
  }

}
</script>
