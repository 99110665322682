<template>
  <div class="swiper-wrapper">
    <div class="swiper-slide" v-for="(slide, index) in slides" :key="index">
      <div class="carousel-item-body">

        <div class="home-header custom-header">

<!--          <div class="header-content-alt bg-cover" v-if="slide.url"-->
<!--               :style="getStyle(slide)" @click="goToSlide(slide)" :link="slide.link">-->
            <!--              <router-link :to="{ path: slide.link }" style="display: block"></router-link>-->
<!--          </div>-->
          <img alt="" v-if="slide.url" style="cursor:pointer;" :src="slide.url" @click="goToSlide(slide)" :link="slide.link">
          <div class="header-content" v-else-if="slide.header || slide.subtitle">
            <h1 v-html="slide.header"></h1>
            <div class="subtitle" v-html="slide.subtitle"></div>
            <div class="button-holder" v-if="slide.button && slide.url">
              <router-link :to="{ name: 'catalog', params: { category: slide.url } }" class="default-btn ">
                <span class="btn-text" style="margin-right: 0">{{ $static('home.to_catalog', 'До каталогу') }}</span>
              </router-link>
            </div>
          </div>
        </div>

        <!--        <div class="product-label-holder">-->
        <!--          <div class="product-label label-new" v-if="slide.new">-->
        <!--            <span class="label-text">New</span>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="text">-->
        <!--          <div class="name h2">{{ $dynamic(slide, slide.title, 'title') }}</div>-->
        <!--          <p class="caption">{{ $dynamic(slide, slide.subtitle, 'subtitle') }}</p>-->
        <!--          <span class="price-element">{{ $dynamic(slide, slide.price, 'price') }}</span>-->
        <!--          <a href="javascript:void(0)" class="item-link" @click="goToSlide(slide)">-->
        <!--            Детальніше-->
        <!--            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--              <path-->
        <!--                  d="M12.5 10L12.8536 9.64645L13.2071 10L12.8536 10.3536L12.5 10ZM7.85355 4.64645L12.8536 9.64645L12.1464 10.3536L7.14645 5.35355L7.85355 4.64645ZM12.8536 10.3536L7.85355 15.3536L7.14645 14.6464L12.1464 9.64645L12.8536 10.3536Z"-->
        <!--                  fill="white"/>-->
        <!--            </svg>-->
        <!--          </a>-->
        <!--        </div>-->
        <!--        <div class="image bg-cover" :style="{'background-image': `url(${slide.url})`}"></div>-->
      </div>
    </div>
  </div>
  <div class="swiper-pagination"></div>
</template>

<script>
export default {
  name : 'HomeSlides',
  props: ['slides'],
  data() {
    return {
      swiper: undefined
    }
  },
  methods: {
    goToSlide(slide) {
      if (!slide.link) {
        return
      }
      if (!slide.link.startsWith('https')) {
        this.$router.push(slide.link)
        return
      }
      // console.log(slide)
      let path = new URL(decodeURI(slide.link))
      // console.log(path)
      let goTo = `${path.pathname}`
      if (path.search) {
        goTo += `?${path.search}`
      }
      // console.log(goTo)
      this.$router.push(goTo)
    },
    getStyle(slide) {
      let url = slide.url;

      let style = {}

      style['background-image'] = this.$filters.getItemImage(url)

      if (slide.link) {
        style['cursor'] = 'pointer'
      }
      return style
    }
  },
  mounted() {
    if (!this.swiper && this.slides.length > 1) {
      // this.slides = document.querySelectorAll('.swiper-slide');
      this.swiper = new window.Swiper('.main-gallery', {
        //effect: 'fade',
        slidesPerView: 1,
        loop         : true,
        spaceBetween : 15,
        speed        : 700,
        autoplay     : {
          delay               : 3000,
          disableOnInteraction: false
        },
        pagination   : {
          el            : '.swiper-pagination',
          dynamicBullets: false
        }
      });
    }
  }
}
</script>